// ********************************************************
// DIVIDER
// ********************************************************

.divider,
.s-divider {
	// border: 1px dotted blue;
	width: 100%;
	height: 2rem;
	// float: left;
	@include clearFix();

	&.thin {
		height: 0.5rem;
	}

	&.horizontal {
		&.hidden {
		}
	}
}
