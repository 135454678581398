// ********************************************************
// Accents
// ********************************************************

$colorShadow: color("grey", "base") !default;

$subtleShadow:
  0px 1px 2px 0 $colorShadow
;
$floatingShadow:
  0px 2px 4px 0px rgba(34, 36, 38, 0.12),
  0px 2px 10px 0px rgba(34, 36, 38, 0.15)
;
