// ********************************************************
// FIGURE handling
// ********************************************************

figure {
	text-align: center;
	figcaption {
		font-family: $fontFamilySansSerif;
		font-size: smaller;
		@extend .grey-text;
	}

	&.micro { @extend img.s-img.micro; }
	&.mini { @extend img.s-img.mini; }
	&.small { @extend img.s-img.small; }
	&.junior { @extend img.s-img.junior; }
	&.medium { @extend img.s-img.medium; }
	&.large { @extend img.s-img.large; }
	&.extralarge { @extend img.s-img.extralarge; }
	&.huge { @extend img.s-img.huge; }
	&.massive { @extend img.s-img.massive; }

	max-width: 80vw;

	&.center {
		margin-left: auto;
		margin-right: auto;
	}
// s-figure
// ...size

}