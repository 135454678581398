// ********************************************************
// COMMON MIXINS
// ********************************************************

// Usage: @include clearFix();
@mixin clearFix(){
	&:before,
	&:after{
		content: " "; 
		display: table; 
	}

	&:after{
		display: block;
		clear: both;
		height: 1px;
		margin-top: -1px;
		visibility: hidden;
	}
	&{
		*zoom: 1;
	}
}

/*------------------------
https://codepen.io/davidhalford/pen/wlDxL

mixin that calculates if text needs to be light or dark
depending on the background color passed.

Color brightness is determined by the following formula: 
((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
------------------------*/
@mixin text-contrast($n) {
  $color-brightness: round((red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);
  
  @if abs($color-brightness) < ($light-color/2){
    // color: rgba(255, 255, 255, 0.8) !important;
    color: white !important;
  }

  @else {
    // color: $off-black !important;
  }
}
// .c000000{background-color:#000000; @include text-contrast(#000000);}
// .c000022{background-color:#000022; @include text-contrast(#000022);}
// .c000044{background-color:#000044; @include text-contrast(#000044);}
// .c000066{background-color:#000066; @include text-contrast(#000066);}
// .c000099{background-color:#000099; @include text-contrast(#000099);}
// .c0000aa{background-color:#0000aa; @include text-contrast(#0000aa);}


/*------------------------
RESPONSIVE SCREEN SIZES
https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
	// usage
	.my-box {
	  padding: 10px;
	  
	  @include for-desktop-up {
	    padding: 20px;
	  }
	}
------------------------*/

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

