// ********************************************************
// MASTHEAD
// ********************************************************

// div { border: 1px dotted yellow; }

.s-masthead {
	position: relative;
	background: black;

	#masthead-image-container {
		// background-image: url('/images/branding/summers-battlefield.jpg');
		height: 20rem;
		width: 100%;
		background-size: cover;
		background-position: center center;
		// border: 2px dotted yellow;
		@include for-phone-only {
			height: 15rem;
		}
		@include for-desktop-up {
			height: 25rem;
		}
		@include for-big-desktop-up {
			height: 30rem;
		}
		#masthead-gradient-container {
			// width: 100%;
			height: 100%;
			// border: 3px dotted orange;
			// Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.8+50,0+100
			// FF3.6-15
			background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%); 
			// Chrome10-25,Safari5.1-6
			background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0) 100%); 
			// W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
			background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0) 100%); 
			// IE6-9
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=1 ); 
		}
	}

	div#masthead-video {
		transition: filter 1s ease-out;
		background-color: rgba(0, 0, 0, 0);
		video {
			position: relative;
			z-index: 50;
			min-width: 100%;
			height: auto;
		}
	}

	// Filter and hovers for not-safari
	div#masthead-video {
        filter: sepia(100%) brightness(100%) hue-rotate(60deg);
	}
	&:hover div#masthead-video {
        // filter: sepia(0%) brightness(40%) hue-rotate(0deg) url("#turbuMap");
        filter: sepia(0%) brightness(40%) hue-rotate(0deg);
	}

	.row {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// border: 1px dotted blue;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: stretch;
		// align-items: center;
		z-index: 100;
		// background-color: purple;

		.left-side,
		.right-side {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 1 auto;
			// background-color: purple;
		}
		.right-side {
			flex: 0 1 auto;
		}
		.right-side-landing {
			// display: block;
			display: flex;
			// justify-content: center;
			// align-items: center;
			// background-color: orange;
			img#landing-book-cover {
				// max-height: 70%;
				width: 90%;
				height: auto;
				// width: auto;
				padding: 1rem;
				// margin: auto auto;
				@include for-phone-only {
					min-width: 100%;
					max-width: 100%;
					height: auto;
					padding: 0 1rem 0 0;
					align-self: center;
				}
				@include for-desktop-up {
					min-height: 90%;
					max-height: 90%;
					width: auto;
				}
			}
		}
		.right-side-inner {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			// background-color: green;
			// justify-content: center;
			// background-color: purple;
			height: 100%;
			width: 100%;

			.inner-title {
				align-items: flex-end;
				flex: 1 1 auto;
			}

			// .inner-title {
			// 	background-color: gray;
			// 	flex: 1 0 auto;
			// 	align-items: center;
			// 	// justify-content: center;
			// }
			.inner-leader {
				// background-color: orange;
				// border: 1px solid red;
				flex: 1 0 auto;
				form {
					display: block;
					background: blue;
					height: 100%;
				}
			}
		}

		.left-side {
			flex-direction: column;
			// align-items: stretch;
			// justify-content: stretch;
			// background-color: green;
			.left-side-inner {
				// NOTE: landing page version is below this
				// height: 100%;
				padding: 2rem 2rem;
				// background-color: green;

				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: stretch;
				.inner-title, .inner-leader, .inner-meta {
					display: flex;
					// border: 1px solid white;
					flex: 1 1 auto;
					justify-content: center;
				}
				.inner-title {
					// background-color: blue;
					align-items: flex-end;
					text-align: center;
					img#book-title {
						height: 12rem;
						width: auto;
						margin: 0 auto;
						// border: 1px solid red;
						@include for-phone-only {
							height: 8rem;
							width: auto;
							// border: 1px solid blue;
						}
						@include for-desktop-up {
							height: 14rem;
							width: auto;
							// border: 1px solid blue;
						}
						@include for-big-desktop-up {
							// width: 35vw;
							height: 15rem;
							width: auto;
							// border: 2px solid green;
						}
					}

					h1, h2, h3, h4, h5, h6 {
						font-family: $fontFamilySansSerif;
						color: rgba(255, 255, 255, 0.9);
						font-weight: 300;
						font-size: 6vw;
						@extend .shadowed;
						// @extend .flow-text;
						@include for-phone-only {
							font-size: 8vw;
						}
					}
				}
				.inner-leader {
					// background-color: blue;
					flex: 0 0 auto;
					align-self: center;

					font-family: $fontFamilySansSerif;
					font-weight: 100;
					margin: 0;
					color: white;
					@extend .shadowed;
					text-align: center;
					@include for-phone-only {
						width: 80%;
						font-size: 1rem;
						line-height: 1.1;
						// border: 1px solid red;
					}
					@include for-tablet-portrait-up {
						width: 80%;
						font-size: 1.5rem;
						line-height: 1.1;
						// border: 1px solid blue;
					}
					@include for-desktop-up {
						width: 80%;
						font-size: 2rem;
						// border: 1px solid green;
					}
					@include for-big-desktop-up {
						width: 60%;
						font-size: 2rem;
						// border: 1px solid orange;
					}
				}
				.inner-meta {
					// background: blue;
					flex: 0 0 auto;
					flex-direction: column;
					text-align: center;
					div {
						display: block;
						width: 100%;
						padding: 0.5rem 10vw;

						color: white;
						@extend .shadowed;
					}
					.inner-meta-attribution {
						font-family: $fontFamilySerif;
						font-size: 1.5vw;
					}
					.inner-meta-tags {}
				}
			
				&.landing-page-version {
					.inner-title {
						align-items: flex-end;
						flex: 1 1 auto;
						// img#book-title {
						// 	width: 80%;
						// 	height: auto;
						// }
						// background: purple;
					}
					.inner-leader {
						flex: 1 0 auto;
						// background: orange;
						padding: 0 4rem;
					}
					.inner-meta {
						// background: blue;
						flex: 1 0 auto;
						flex-direction: row;
					}
				}

			}
		}
	}
}
