// ********************************************************
// VARIABLES
// Starting point is MaterializeCSS's variables
// Copy them here and set them so they're not alter overwritten
// ********************************************************


// 1. Colors
// ==========================================================================

$primary-color: color("materialize-red", "base") !default;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;

$secondary-color: color("orange", "base") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;



// Media Query Ranges
$tiny-screen: 420px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$tiny-screen-up: ($tiny-screen + 1px) !default;
$small-screen-up: ($small-screen + 1px) !default;
$medium-screen-up: ($medium-screen + 1px) !default;
$large-screen-up: ($large-screen + 1px) !default;

$small-and-up: "only screen and (min-width : #{$tiny-screen-up})" !default;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$tiny-and-down: "only screen and (max-width : #{$tiny-screen})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// For full responsive, use this:
@media #{$small-and-up} {}
@media #{$medium-and-up} {}
@media #{$large-and-up} {}
@media #{$extra-large-and-up} {}
@media #{$tiny-and-down} {}
@media #{$small-and-down} {}
@media #{$medium-and-down} {}
@media #{$medium-only} {}

