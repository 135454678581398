// ********************************************************
// QUOTATIONS
// ********************************************************

$quotationRowGutter: 1rem;

.s-quotation-row {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	align-items: stretch;
}

.s-quotation-item {
	// equal width:
	width: 100%;
	flex: 1 1 auto;
	align-items: flex-start;

	// stack child elements
	display: flex;
	flex-direction: column;

	// space between items
	&:not(:last-child) {
		margin-right: $quotationRowGutter;
	}

	padding: 1rem;
	&:not(:last-child) {
		border-right: 1px solid rgba(50, 50, 50, 0.3);
	}
}

.s-quotation-standalone,
.s-quotation-item {
	.quote {
		display: block;
		width: 100%;
		font-family: $fontFamilySerif;
		font-size: 110%;
		font-weight: 700;
		text-align: center;
		align-self: flex-start;
	}
	.attribution {
		display: block;
		width: 100%;
		font-family: $fontFamilySansSerif;
		font-size: 90%;
		text-align: center;
	}
}