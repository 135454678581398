// ********************************************************
// CARD FOR BOOK GRID
// ********************************************************


.book-cards {
	// border: 1px dashed green;


	&.longer-works {
		&:hover .content-card-book .series-float {
			// when BOOK row is hovered, show series numbers
			opacity: 1;
		}
		.content-card-book {
			position: relative;

			a {
				z-index: 50;
			}

			.series-float {
				// Inner organization
				display: flex;
				align-items: center;
				justify-content: center;

				// Position: float near top
				z-index: 100;
				position: absolute;
				top: -2rem;
				right: -1rem;

				// Size
				width: 6rem;
				height: 6rem;

				// Appearance
				border: 3px solid white;
				border-radius: 10rem;
				background-color: color("blue", "lighten-2");
				color: white;
				font-family: $fontFamilySansSerif;
				font-weight: 700;
				// Shadow
				box-shadow: 1px 1px 5px black;

				// Hide until hover
				opacity: 0;

				// Transition speed for opacity
				// transition: opacity 1s linear;
				transition: opacity 0.6s ease-out;
			}
		}
	}
	
	&.shorter-works {
		display: flex;
		justify-content: center;
		align-items: center;
		// border: 3px solid red;

		div.content-card-book.col.s3 {
			// margin-left: none !important;
			// border: 3px solid #cc0000;
			margin-left: none !important;
		}		
	}
}
