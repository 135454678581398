// ********************************************************
// ICON HANDLING
// ********************************************************

$sizeIconBase: 1rem;
$sizeIconMultiplier: 1.1;


.background-color-facebook  { background-color: rgb(59, 89, 152); }
.background-color-twitter   { background-color: rgb(29, 161, 242); }
.background-color-pinterest { background-color: rgb(203, 32, 39); }

.s-icon {
	&.micro {
		font-size: $sizeIconBase;
	}
	&.mini {
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 1.5));
	}
	&.small {
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 2));
	}
	&.junior {
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 3));
	}
	&.medium {
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 4));
	}
	&.large {
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 5.5));
	}
	&.huge {
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 7));
	}
	&.massive {
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 8.5));
	}
}

// Socicons are manually scaled to fit the 2 floating button sizes:
.s-button {
	&.btn-floating.spaced {
		margin-right: 0.5rem;
	}
	&.btn-floating .s-icon {
		margin-left: 0.5rem;
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 1.5));
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 1.5));
	}
	&.btn-floating.btn-large .s-icon {
		margin-left: -0.1rem;
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 2));
		font-size: ($sizeIconBase * ($sizeIconMultiplier * 2));
	}
}

// This might not be useful:
.XXX.s-button.btn-floating .s-icon {
	&.micro {
		line-height: 1.75 * $sizeIconBase;
	}
	&.mini {
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 1.5));
	}
	&.small {
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 2));
	}
	&.junior {
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 3));
	}
	&.medium {
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 4));
	}
	&.large {
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 5.5));
	}
	&.huge {
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 7));
	}
	&.massive {
		line-height: 1.75 * ($sizeIconBase * ($sizeIconMultiplier * 8.5));
	}
}