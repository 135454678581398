// ********************************************************
// SUBSCRIBE
// ********************************************************
// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// for-tablet-portrait-up >= 600px wide 
// for-tablet-landscape-up >= 900px wide
// for-desktop-up >= 1200px wide

html.page-subscribe {
	$innerLeftRightPadding: 3rem;

	div.navbar-fixed { display: none; }
	ul#slide-out { display: none; }
	.s-masthead { display: none; }

	// Background
	// Handled on template page

	.logo {
		padding: 1rem 0 0 1rem;
		img {
			max-height: 3rem; width: auto;
			@include for-phone-only {
				max-height: 1.5rem;
			}
		}
		z-index: 100;
		// background-color: blue;
	}

	.layout-container {
		z-index: 50;
		min-height: calc(100vh - 3rem - 4rem);
		// background-color: orange;

		display: flex;
		align-items: center;

		// Debugging
		.s-subscribe {
			// background-color: green;
			// .cta { background-color: orange; }
			// .offer { background-color: red; }
		}

		.s-subscribe {
			display: flex;
			flex-direction: row;
			// width: 100%;
			padding: 0 1rem 0 1rem;
			justify-content: center;

			@include for-tablet-landscape-up {
				padding: 0 2rem 0 2rem;
			}
			@include for-desktop-up {
				margin-top: 3rem;
			}

			.offer {
				display: none;

				@include for-tablet-landscape-up {
					display: flex;
					flex: 1 1 auto;
					align-items: center;
					justify-content: center;
					flex: 0 0 auto;
					width: 40%;

					img {
						max-width: 100%;
						height: auto;
					}
					span {
						display: none;
					}
				}
				@include for-desktop-up {
					width: 30%;
					img {
						max-width: 90%;
					}
				}
			}
			.cta {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: $innerLeftRightPadding;

				color: white;
				color: rgba(255, 255, 255, 0.95);

				@include for-tablet-portrait-up {
					background-color: rgba(0, 0, 0, 0.6);
					border-radius: 1rem;
					flex: 0 0 auto;
					width: 80%;
				}
				@include for-tablet-landscape-up {
					width: 60%;
				}
				@include for-desktop-up {
					width: 45%;
					// border: 2px solid yellow;
				}
				@include for-big-desktop-up {
					width: 40%;
					// border: 6px dashed blue;
				}

				img {
					max-height: 300px;
					width: auto;
					@include for-tablet-portrait-up {
						max-height: 450px;
					}
					@include for-tablet-landscape-up {
						display: none;
					}
				}


				h1 {
					width: 100%;
					// text-align: center;
					@include for-phone-only {
						text-align: center;
					}
				}
				h2 {
					// secondary CTA
					@extend .padding-bottom;
					@extend .leader;
					font-size: 1.5rem;
					@include for-phone-only {
						text-align: center;
					}
				}
				form {
					width: 80%;
					margin: auto auto 1rem auto;
					@include for-tablet-landscape-up {
						width: 100%;
						padding: 1.5rem;
						background-color: rgba(0, 0, 0, 0.87);
						margin: 0 1rem 1rem 1rem;
						border-radius: 1rem;
					}
				}
				.rules {
					color: rgba(255, 255, 255, 0.75);
					font-size: 0.8rem;
					font-family: $fontFamilySansSerif;
					line-height: 1.1;
				}
			}
		}
	}

	footer {
		z-index: 75;
		height: 3rem;
		padding: 5px;
		color: white;
		background-color: black;
		text-align: center;
		font-family: $fontFamilySansSerif;
		font-size: 0.8rem;
		letter-spacing: 0.5px;
	}
}
