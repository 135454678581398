// ********************************************************
// TYPOGRAPHY
// ********************************************************
// NOTE: $off-black = black with some transparency

html{
    line-height: 1.5;

    @media only screen and (min-width: 0) {
        font-size: 14px;
    }

    @media only screen and (min-width: $medium-screen) {
        font-size: 14.5px;
    }

    @media only screen and (min-width: $large-screen) {
        font-size: 15px;
    }

    font-family: $fontFamily;
    font-weight: normal;
    color: $off-black;
}

// HEADERS
h1, h2, h3, h4, h5, h6 {
    font-family: $fontFamilyHeader;
    font-weight: 400;
    line-height: 1.1;
}
h3, h4, h5, h6 {
    font-weight: 300;
}
h1 {
    font-size: 2.5rem;
    margin: 2.1rem 0 0 0;
}
h1,h2 {
    line-height: 110%
}
h2 {
    font-size: 2.2rem;
    margin: 1.78rem 0 0 0;
}
h3 {
    font-size: 1.8rem;
    margin: 1.46rem 0 0 0;
}
h3,h4 {
    line-height: 110%
}
h4 {
    font-size: 1.4rem;
    margin: 1.14rem 0 0 0;
}
h5 {
    font-size: 1.1rem;
    margin: .82rem 0 0 0;
}

h5,h6 {
    line-height: 110%
}

h6 {
    font-size: 1rem;
    margin: .5rem 0 .4rem
}



// OTHER
strong { font-weight: 700; }

.leader {
    @extend .text-weight-light;
    font-family: $fontFamilySansSerif;
    font-weight: 300;
    font-size: 110%;
    // &.large {
    //     font-size: 120%;
    // }
    // &.huge {
    //     font-size: 140%;
    // }
}
h1, h2, h3, h4, h5, h6 {
    & + .leader {
        margin: 0;
    }
}

.padding-top {
    padding-top: 1rem;
    &.extra {
        padding-top: 2rem;
    }
}
.padding-bottom {
    padding-bottom: 1rem;
    &.extra {
        padding-bottom: 2rem;
    }
}
.no-top-margin {
    margin-top: 0;
}

// Lists -- show unordered lists for page content!
.s-page-content {
    // .flow-text {
        ul {
            padding-left: 30px;
            margin-top: 10px;
            margin-bottom: 15px;
        }
        ul li {
            list-style-type: initial;
        }
    // }
}