// ********************************************************
// SLIDER for landing page
// ********************************************************

.slider-dots {
    // border: 1px solid red;
    height: 2rem; 
    max-height: 2rem;
    padding: 0 0 1rem 0;
    margin-bottom: 1rem;
    // padding: 0;
    // margin: 0;
    z-index: 100;

    .slick-dots {
        bottom: 0;
        position: relative;
    }
}

.quote-slider {
    .one-quote {
        .stars {}
        p {
            line-height: 1.2;

            &.attribution {
                font-size: 90%;
            }
        }
        p:first-of-type {
            line-height: 1.5;
        }
    }
}