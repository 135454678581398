// ********************************************************
// BOOK PAGE
// ********************************************************

.s-book-page {

	&.s-description {

		.heroline {
			font-size: 3rem;
			margin: 0 0 2rem 0;
		}

		.leader {
			font-size: 1.5rem;
			margin: 1rem 0 2rem 0;
		}

	}
}