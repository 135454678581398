// ********************************************************
// CARD FOR BOOKS
// ********************************************************
a.s-badge {
	color: white;
}

.s-badge {
	font-family: $fontFamilySansSerif;
	border-radius: 30rem;
	white-space: nowrap;
	margin: 0.3rem 0 0.3rem 0;
	padding: 0.2rem;

	&.micro {
		// padding-left: (1rem * 0.5) / 2;
		// padding-right: (1rem * 0.5) / 2;
		font-size: (1rem * 0.5);
		height: ((1rem * 0.5) * 1.5);
		line-height: ((1rem * 0.5) * 1.7);
		letter-spacing: 0.7px;
	}
	&.mini {
		// padding-left: (1rem * 0.7) / 2;
		// padding-right: (1rem * 0.7) / 2;
		font-size: (1rem * 0.7);
		height: ((1rem * 0.7) * 1.5);
		line-height: ((1rem * 0.7) * 1.5);
		letter-spacing: 1px;
	}
	&.small {
		padding-left: (1rem * 0.9) / 2;
		padding-right: (1rem * 0.9) / 2;
		font-size: (1rem * 0.9);
		height: ((1rem * 0.9) * 1.5);
		line-height: ((1rem * 0.9) * 1.5);
	}
	&.junior {
		padding-left: (1rem * 1) / 2;
		padding-right: (1rem * 1) / 2;
		font-size: (1rem * 1);
		height: ((1rem * 1) * 1.5);
		line-height: ((1rem * 1) * 1.5);
	}
	&.medium {
		padding-left: (1rem * 1) / 2;
		padding-right: (1rem * 1) / 2;
		font-size: (1rem * 1);
		height: ((1rem * 1) * 1.5);
		line-height: ((1rem * 1) * 1.5);
	}
	&.large {
		padding-left: (1rem * 1.5) / 2;
		padding-right: (1rem * 1.5) / 2;
		font-size: (1rem * 1.5);
		height: ((1rem * 1.5) * 1.5);
		line-height: ((1rem * 1.5) * 1.5);
	}
	&.huge {
		padding-left: (1rem * 2) / 2;
		padding-right: (1rem * 2) / 2;
		font-size: (1rem * 2);
		height: ((1rem * 2) * 1.5);
		line-height: ((1rem * 2) * 1.5);
	}
	&.massive {
		padding-left: (1rem * 2.5) / 2;
		padding-right: (1rem * 2.5) / 2;
		font-size: (1rem * 2.5);
		height: ((1rem * 2.5) * 1.5);
		line-height: ((1rem * 2.5) * 1.5);
	}


}
