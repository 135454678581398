// ********************************************************
// IMAGES
// ********************************************************

img,
img.image,
img.s-img {
	// &:not(.responsive-img) {
	// 	max-width: 80vw;
	// }
	// display: inline-block;
	// vertical-align: middle;

	&.iconic {
		width: 16px;
		height: auto;
		&.is-portrait {
			max-height: 16px;
			width: auto;
		}
	}
	&.micromini {
		width: 24px;
		height: auto;
		&.is-portrait {
			max-height: 24px;
			width: auto;
		}
	}
	&.micro {
		width: 32px;
		height: auto;
		&.is-portrait {
			max-height: 32px;
			width: auto;
		}
	}
	&.mini {
		width: 64px;
		height: auto;
		&.is-portrait {
			max-height: 64px;
			width: auto;
		}
	}
	&.small {
		width: 100px;
		height: auto;
		&.is-portrait {
			max-height: 100px;
			width: auto;
		}
	}
	&.junior {
		width: 150px;
		height: auto;
		&.is-portrait {
			max-height: 150px;
			width: auto;
		}
	}
	&.medium {
		width: 250px;
		height: auto;
		&.is-portrait {
			max-height: 250px;
			width: auto;
		}
	}
	&.large {
		width: 450px;
		height: auto;
		&.is-portrait {
			max-height: 450px;
			width: auto;
		}
	}
	&.extralarge {
		width: 640px;
		height: auto;
		&.is-portrait {
			max-height: 640px;
			width: auto;
		}
	}
	&.huge {
		width: 700px;
		height: auto;
		&.is-portrait {
			max-height: 700px;
			width: auto;
		}
	}
	&.massive {
		width: 800px;
		height: auto;
		&.is-portrait {
			max-height: 800px;
			width: auto;
		}
	}
}

