// ********************************************************
// BUTTON handling
// ********************************************************

$buttonImageVertMargin: 4px;

.s-text-button,
.s-button:not(.btn-floating),
.s-buy-button,
.btn,
.btn-flat {
	font-family: $fontFamilySansSerif;
	font-weight: 300;
	border-radius: 30rem;
	padding: 0 2rem 0 2rem;

	img,
	img.s-img {
		max-height: (36px - ( $buttonImageVertMargin * 2));
		width: auto;
		margin: $buttonImageVertMargin 0.5rem $buttonImageVertMargin 0;
		float: left;
	}

	&.center,
	&.centered {
		// border: 5px solid red;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}

	&.btn-large {
		img,
		img.s-img {
			// border: 1px dotted yellow;
			max-height: (54px - ( $buttonImageVertMargin * 2));
			width: auto;
			margin: $buttonImageVertMargin 0.5rem $buttonImageVertMargin 0;
			float: left;
		}
	}

	&,
	&.s-text-button {
		@extend .box-reset-styles;
		display: flex;
    	align-items: center;
    	justify-content: center;
    	margin-left: auto;
    	margin-right: auto;
    	padding: 0.5rem 0 0.5rem 0;
    	border-radius: 30rem;

    	&.shaded:hover {
    		@extend .shaded.darker;
    	}

    	.s-icon {
    		padding: 0 0.5rem 0 0.5rem;
    	}
	}

}

.s-buy-button {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 1rem;
	margin-bottom: 2rem;

	img,
	img.s-img {
		margin-left: -1rem !important;
	}
}

.s-icon-button {
	display: inline-block;
	margin: 0rem !important;
	padding: 1rem !important;
	i.large {
		margin-top: 0.2rem;
		background-color: rgba(0, 0, 0, 0);
	}
}
.s-seethrough {
	@extend .tinted-background;
}


