// ********************************************************
// CARD FOR MISC
// ********************************************************

.s-card-left-date {
	font-family: $fontFamilySansSerif;
	font-weight: 300;
	color: color("grey", "darken-3");
	@include for-phone-only {
		padding: 0 2rem !important;
	}
}
.s-card {
	.card-image {
		background-color: none;
	}

	h1,h2,h3,h4,h5,h6 {
		margin: 0;
		padding: 0;
	}

	// Default card
	&.card.horizontal {
		// Debugging
		// @include for-phone-only {
		// 	background-color: blue;
		// }
		// @include for-tablet-portrait-up {
		// 	background-color: red;
		// }
		// @include for-desktop-up {
		// 	background-color: green;
		// }
		// @include for-big-desktop-up {
		// 	background-color: orange;
		// }
		
		.card-stacked {
			// fixes iPhone problem with long vertical scale of image:
			max-height: 175px;
			overflow: hidden;
		}

		.card-image {
			background-color: #dedede;
			img {
				// fixes iPhone problem with long vertical scale of image:
				width: 175px;
				height: 175px;
			}

			@include for-phone-only {
				// width: 40%;
			}
			@include for-tablet-portrait-up {
				// width: 30%;
			}
			@include for-desktop-up {
				// width: 20%;
			}
			@include for-big-desktop-up {
				// width: 15%;
			}
		}

		.card-content {
			a { color: rgba(0, 0, 0, 0.87); }
			
			.s-card-subtitle {
				color: color("grey", "lighten-1"); 
			}
			.s-card-date {
				padding: 0 0 0 0.5rem;
				font-size: 1rem;
				color: color("grey", "lighten-2"); 
				float: right;
				@include for-phone-only {
					float: none;
					padding-bottom: 1rem;
				}
			}
			@include for-phone-only {
				h4 { font-size: 5vw; }
				.s-card-subtitle { font-size: 3vw; }
			}
			@include for-tablet-portrait-up {
				h4 { font-size: 3vw; }
				.s-card-subtitle { font-size: 2vw; }
			}
			@include for-desktop-up {
				h4 { font-size: 2vw; }
				.s-card-subtitle { font-size: 1vw; }
			}
			@include for-big-desktop-up {
				h4 { font-size: 1.8vw; }
				.s-card-subtitle { font-size: 1vw; }
			}
		}
		.s-card-meta {
			&.date {
				// font-weight: 400;
				font-weight: bolder;
				// color: $grey;
				color: map-get($grey, base);
			}
		}
	}


	&.s-brief {
		background-color: color("grey", "lighten-3");
		// box-shadow: none;
		border-radius: 3rem;

		.card-image,
		.card-image img {
			border-top-left-radius: 3rem;
			border-bottom-left-radius: 3rem;
		}

		.card-content {
			padding-top: 1rem;
			padding-bottom: 1rem;
			padding-right: 1rem;

			// h1, h2, h3, h4 {
			// 	font-size: 1.5rem;
			// }

			.s-card-meta {
				padding-top: 1rem;
			}
		}
	}

	// &.s-card-horizontal {
	// 	img,
	// 	.card-image {
	// 		width: 175px !important;
	// 		height: 175px !important;
	// 	}
	// }

	&.s-card-image {
		text-align: center;
		h3 {
			font-style: italic;
			font-size: 1.5rem;
			width: 66%;
			margin: 0 auto;
		}
		img {
			width: 100%;
			height: auto;
			@include for-phone-only {
				padding: 0 1rem 0 1rem;
			}
		}
	}

	// .s-post-list.row &.s-quotation {}
	&.s-quotation {
		// border-top: 1px solid color("grey", "base");
		// border-bottom: 1px solid color("grey", "base");

		display: flex;
		flex-direction: row;
		padding-top: 2rem;
		margin-top: 2rem;
		margin-bottom: 1rem;

		&:before, &:after {
			font-family: $fontFamilyIcons;
			font-size: 3rem;
		    // font-size: 16px; 
		    font-size: 3vw;

			margin: 0 1rem;
			color: color("grey", "base");
		}

		&:before {
			content: "\f10d";
		}
		&:after {
			content: "\f10e";
		}

		.content {
			font-family: $fontFamilySerif;
		    font-size: 3vw;
		    @include for-phone-only {
		    	font-size: 5vw;
		    }
			@include for-desktop-up {
			    font-size: 2.5vw;
			}
			@include for-big-desktop-up {
			    font-size: 2vw;
			}

			p > em {
				&:before {
					content: "\2014";
				}
				display: block;
				text-align: right;
			    font-size: 2vw;
			    @include for-phone-only {
			    	font-size: 3vw;
			    }
			}
		}
	}
}

