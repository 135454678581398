// ********************************************************
// footer
// ********************************************************

footer.page-footer {
	font-family: $fontFamilySansSerif;

	& > .row {

		& > .col {
			max-height: 24rem;

			.s-book-image {
				max-height: 20rem;
				padding: 2rem 1rem;
				width: auto;
			    @media only screen and (min-width: 0) {
			        height: 50vw;
			    }
			}

			ul.s-inverted.s-ul.collection {
				border: none;
				li {
					a.collection-item {
						background-color: rgba(0, 0, 0, 0) !important;
					}
				}
			}

			&:not(:last-child) {
				// border-right-width: 1px;
				// border-right-style: solid;
				// border-right-color: rgba(255, 255, 255, 0.6);
			}
		}

		.col {
			// display: table-cell;
		}
	}

	.footer-copyright {
		@extend .text-weight-light;
	}
}
