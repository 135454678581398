// ********************************************************
// EXTENDERS
// ********************************************************
// These styles are used to extend the other styles

// Usage: in a scss style definition: 
// @extend .style1, .style2
.text-weight-light { font-weight: 100; }
.shadowed,
.dropshadow-text {
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
}
.shaded,
.tinted-background {
    background-color: rgba(0, 0, 0, 0.4);

    &.darker {
        background-color: rgba(0, 0, 0, 0.6);
    }
}

.box-reset-styles {
    display: block;
    // border: 5px dotted orange !important;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    float: none;
    width: auto;
    height: auto;
    // width: initial;
    // height: initial;
}