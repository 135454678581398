// ********************************************************
// FONT HANDLING
// https://dev.opera.com/articles/better-font-face/
// https://www.filamentgroup.com/lab/font-loading.html
// ********************************************************

@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i');
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,500i,700,900');

$font-path: "../fonts/" !default;

$fontFamily: "Lora", Georgia, serif;
$fontFamilySerif: "Lora", Georgia, serif;
// $fontFamilyHeader: "Roboto", "Helvetica Neue", sans-serif;
$fontFamilyHeader: $fontFamilySerif;
$fontFamilySansSerif: "Roboto", "Helvetica Neue", sans-serif;
$fontFamilyManuscript: "Manuscript", "Lora", serif;
// $fontFamilyIconIcon: "Socicon";
$fontFamilyIconSocicon: "Socicon";
$fontFamilyIcons: "FontAwesome";

// Social icons
@font-face {
  font-family: "Socicon";
  src: url("#{$font-path}Socicon.eot?xvgs9q");
  src: url("#{$font-path}Socicon.eot?xvgs9q#iefix") format("embedded-opentype"), url("#{$font-path}Socicon.woff2?xvgs9q") format("woff2"), url("#{$font-path}Socicon.ttf?xvgs9q") format("truetype"), url("#{$font-path}Socicon.woff?xvgs9q") format("woff"), url("#{$font-path}Socicon.svg?xvgs9q#Socicon") format("svg");
  font-weight: normal;
  font-style: normal;
}

// Manuscript
@font-face {
  font-family: "Manuscript";
  src: url("#{$font-path}Arapey-Regular.ttf");
  src: url("#{$font-path}Arapey-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
// Manuscript
@font-face {
  font-family: "Manuscript";
  src: url("#{$font-path}Arapey-Italic.ttf");
  src: url("#{$font-path}Arapey-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}



/// Main font
@font-face {
    font-family: "Roboto";
    src: local(Roboto Thin),
        url("#{$font-path}Roboto-Thin.woff2") format("woff2"),
        url("#{$font-path}Roboto-Thin.woff") format("woff");

    font-weight: 100;
}
@font-face {
    font-family: "Roboto";
    src: local(Roboto Light),
        url("#{$font-path}Roboto-Light.woff2") format("woff2"),
        url("#{$font-path}Roboto-Light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Regular), 
        url("#{$font-path}Roboto-Regular.woff2") format("woff2"),
        url("#{$font-path}Roboto-Regular.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Medium),
        url("#{$font-path}Roboto-Medium.woff2") format("woff2"),
        url("#{$font-path}Roboto-Medium.woff") format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Bold),
        url("#{$font-path}Roboto-Bold.woff2") format("woff2"),
        url("#{$font-path}Roboto-Bold.woff") format("woff");
    font-weight: 700;
}
