@charset "UTF-8";
// ********************************************************
// MAIN SITE FILE FOR WEBSITE
// Starting point is materialize-css/sass/materialize.scss
// But swap out / preempt with my own libs
// ********************************************************


// Mixins
@import "materialize-css/sass/components/mixins";
@import "mixins.scss";

// @import "materialize-css/sass/components/color";
@import "color.scss";

// Variables;
@import "variables.scss";
@import "materialize-css/sass/components/variables";

// Reset
@import "materialize-css/sass/components/normalize";

// components
@import "materialize-css/sass/components/global";
@import "materialize-css/sass/components/badges";
@import "materialize-css/sass/components/icons-material-design";
@import "materialize-css/sass/components/grid";
@import "materialize-css/sass/components/navbar";
// @import "materialize-css/sass/components/roboto";
@import "materialize-css/sass/components/typography";
@import "materialize-css/sass/components/transitions";
@import "materialize-css/sass/components/cards";
@import "materialize-css/sass/components/toast";
@import "materialize-css/sass/components/tabs";
@import "materialize-css/sass/components/tooltip";
@import "materialize-css/sass/components/buttons";
@import "materialize-css/sass/components/dropdown";
@import "materialize-css/sass/components/waves";
@import "materialize-css/sass/components/modal";
@import "materialize-css/sass/components/collapsible";
@import "materialize-css/sass/components/chips";
@import "materialize-css/sass/components/materialbox";
@import "materialize-css/sass/components/forms/forms";
// @import "materialize-css/sass/components/table_of_contents";
@import "materialize-css/sass/components/sideNav";
@import "materialize-css/sass/components/preloader";
// @import "materialize-css/sass/components/slider";
// @import "materialize-css/sass/components/carousel";
@import "materialize-css/sass/components/tapTarget";
@import "materialize-css/sass/components/pulse";
// @import "materialize-css/sass/components/date_picker/default";
// @import "materialize-css/sass/components/date_picker/default.date";
// @import "materialize-css/sass/components/date_picker/default.time";



// @import "color.scss";
// @import "variables.scss";
// @import "materialize-css/sass/materialize.scss";
// @import "mixins.scss";
@import "fonts.scss";
@import "socicons.scss";
@import "font-awesome.scss";
@import "icons.scss";
@import "typography.scss";

@import "extenders.scss"; // near top of styling, these are used throughout
@import "accents.scss";

@import "footer.scss";
@import "card.scss";
@import "card-book.scss";
@import "badges.scss";
@import "divider.scss";
@import "navbar.scss";
@import "images.scss";
@import "manuscriptv2.scss";
@import "page-book.scss";
@import "during-design.scss";
@import "buttons.scss";
@import "figure.scss";
@import "masthead.scss";
@import "modal.scss";
@import "tooltips.scss";
@import "slick.scss";
@import "slick-theme.scss";
@import "slider.scss";
@import "buttons-semantic-ui.scss";
@import "quotations.scss";
@import "clearfix.scss";
@import "page-subscribe.scss";


// These will be more specified and will change later
$colorLink: blue;
$colorLinkInverted: lighten(blue, 25);
$colorInverted: lighten(gray, 25);


.s-inverted {
	color: $colorInverted;
	a {
		color: $colorLinkInverted;
	}
}

.s-ul {
	&.spaced {
		li {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
}


.s-form {
	&.small {
		zoom: 80%;
	}
}