// ********************************************************
// MANUSCRIPTV2, and also simpler EXCERPT for long quotations
// ********************************************************

.excerpt,
.manuscript {
	// NOTE: responsive width for content is handled near the bottom of this definition.

	font-family: $fontFamilyManuscript;

	&.manuscript-launcher {
		margin-bottom: 2rem;
		width: 100%;
		height: 25rem;

		.scroll_frame {
			width: 100%;
			height: 100%;
			// border: 2px solid yellow;
			overflow: auto;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
		}
	}

	.excerpt-iframe {
		width: 100%;
		height: 100%;
		vertical-align: top;

		// padding: 0;
		// height: 25rem;
		// max-height: 25rem;
		// overflow: scroll;
		display: block;
		border: 1px solid rgba(100, 100, 100, 0.3);


		/* Show scrollbars so users know excerpt can be read */
		::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 7px;
		}
		::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0,0,0,.5);
			-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
		}
	}
	a#fullscreen-button {
		@include for-phone-only {
			display: none;
		}

    	color: white;
    	transition: background-color 0.5s ease;
	    background-color: color("grey", "lighten-2") !important;

	    &:hover {
		    background-color: color("grey", "darken-1") !important;
	    	color: white;
	    }

		display: block;
		margin: 0;
		border-top-right-radius: 0px !important;
		border-top-left-radius: 0px !important;
		border-bottom-right-radius: 6px !important;
		border-bottom-left-radius: 6px !important;
		box-shadow: none;
		padding: 0 1rem;
		font-family: $fontFamilySansSerif;

		float: right;
		height: 1.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
		text-transform: none;
	}

	// Use a polyfill with JS sensors to resize text container
	// https://github.com/marcj/css-element-queries
	& .manuscript-container {
	}
	&[min-width~="799px"] .manuscript-container {
	    width: 90%;
	}
	&[min-width~="1000px"] .manuscript-container {
	    // color: red !important;
	    width: 70%;
	}
	&[min-width~="1800px"] .manuscript-container {
	    width: 50%;
	}
}

.excerpt,
.manuscript-embed {
	display: block;
	margin: 0;
	padding: 2rem;

	background-image: url("/images/manuscriptv2-background.jpg");
	background-repeat: repeat;
	background-position: top left;
}


.excerpt,
.manuscript .manuscript-embed .manuscript-container {
	margin: auto;

	h1, h2, h3 {
		font-size: 2rem;
		margin: 20px 0px 10px auto;
		padding: 0px;
		text-indent: 0in;
		font-style: italic;
		font-variant: small-caps;
		letter-spacing: 1px;
		font-style: italic;
	}
	h1 {
		font-weight: 700;
		margin-bottom: 20px;
	}
	h2 {
		font-weight: 700;
		font-style: italic;
	}
	h3 {
		font-weight: normal;
		font-size: 1.5rem;
	}

	p {
		@extend .flow-text;
		text-indent: .5in;
		margin: 0px;
	}

	p:first-child, 
	p:first-of-type,
	hr+p, 
	blockquote+p, 
	h1+p, h2+p, h3+p {
		text-indent: 0in;
	}

	blockquote {
		border-left: none;
		padding: 1rem 2rem 1rem 2rem;
		p {
			font-style: italic;
			@extend .grey-text;
		}
	}

	hr {
		background-image: url("/images/manuscript-png-divider.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		width: 500px;
		height: 10px;
		margin: 60px auto 30px auto;
		margin-left: auto;
		margin-right: auto;
		border: none;
	}

	// http://css-tricks.com/examples/hrs/
	hr.version1-20181112 { 
		// width: 50%;
		width: 40%;
		margin: 60px auto 30px auto;
		height: 30px;
		border-style: solid;
		border-color: black;
		border-width: 1px 0 0 0;
		border-radius: 10px;

		// Rotate:
		// -moz-transform: rotate(10deg);
		// -webkit-transform: rotate(10deg);
		// -o-transform: rotate(10deg);
		// -ms-transform: rotate(10deg);
		// transform: rotate(10deg);

		/* Not really supposed to work, but does */
		// &:before { 
		// 	display: block;
		// 	content: "";
		// 	height: 30px;
		// 	margin-top: -31px;    
		// 	border-style: solid;
		// 	border-color: black;
		// 	border-width: 0 0 1px 0;
		// 	border-radius: 10px;
		// }
	}

	figcaption p {
		font-size: 80%;
		line-height: 1em;
	}

	/* Show scrollbars so users know excerpt can be read */
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0,0,0,.5);
		-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
	}

}