// ********************************************************
// NAVBAR
// ********************************************************

.navbar-fixed {
	nav {
		font-family: $fontFamilySansSerif;
		background-color: rgba(0, 0, 0, 1) !important;

		a.brand-logo img {
			height: calc(4rem - 1rem);
			margin: 0.5rem 0 0.5rem 0;
			width: auto;
		}
		div.nav-wrapper {
			// opacity: 0;
		}

		div {
			// background-opacity; 0 !important;
			background-color: rgba(0, 0, 0, 0) !important;
		}
	}
}

ul.side-nav {
	a.brand-logo img {
		width: calc(300px - 6rem);
		margin: 0;
		height: auto;
	}
	li:not(.top-graphic) {
		border-bottom: 1px solid gray;
	}
}
